import { SVGProps, Ref, forwardRef } from 'react'

const SvgLifeInsurance = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    width="1.5rem"
    height="1.5rem"
    fill="none"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M2.25 3.924v7.614A11.907 11.907 0 0 0 9.882 22.65l1.041.4a3 3 0 0 0 2.154 0l1.041-.4a11.907 11.907 0 0 0 7.632-11.113V3.924a1.488 1.488 0 0 0-.868-1.362A21.7 21.7 0 0 0 12 .75a21.7 21.7 0 0 0-8.882 1.81 1.487 1.487 0 0 0-.868 1.363v0Z"
    />
    <path stroke="currentColor" strokeLinecap="round" strokeWidth={1.5} d="M12 6.5v10M17 11.5H7" />
  </svg>
)

const ForwardRef = forwardRef(SvgLifeInsurance)
export default ForwardRef
